<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
        <MDBContainer fluid class="mt-3">
          <MDBRow>
            <MDBCol md="2">
              <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
            </MDBCol>
            <MDBCol md="2">
              <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
            </MDBCol>
            <MDBCol md="2">
              <select class="form-select" v-model="where.status">
                <option value="">會員狀態</option>
                <option value="1">{{$t("MEMBER.STATUS_1")}}</option>
                <option value="0">{{$t("MEMBER.STATUS_0")}}</option>
              </select>
            </MDBCol>
            <MDBCol class="mb-2 text-end">
              <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
              <MDBBtn color="warning" @click="Export">{{ $t("COMMON.EXPORT") }}</MDBBtn>  
              <MDBBtn color="info" @click="add_dialog = true">{{ $t("DEALER.ADD-ITEM") }}</MDBBtn>  
            </MDBCol>
          </MDBRow>
          <DataTable
            :header="headers"
            :tabledata="desserts"
            v-model:total="total"
            v-model:options="options"
            v-model:loading="loading"
          >
            <template v-slot:name="{ item }">
              {{ (item.first_name)?(item.last_name+item.first_name):item.name }}
            </template>
            <template v-slot:refer="{ item }">
              {{ (item.refer)?`${item.refer.name}`:'' }}
            </template>
            <template v-slot:refer_serial="{ item }">
            {{ (item.refer)?`${item.refer.serial}`:'' }}
          </template>
            <!-- 時間轉換 -->
            <template v-slot:created_at="{ item }">
              {{ Common.TimeFormat(item.created_at) }}
            </template>
            <template v-slot:status="{ item }">
              {{ $t("MEMBER.STATUS_" + item.status) }}
            </template>
            <template v-slot:dealer="{ item }">
              {{ $t("DEALER.STATUS_" + item.dealer) }}
            </template>
            <template v-slot:total="{ item }">
              ${{ (item.statistics.filter(item => item.key == 'total_expend').length > 0)? parseFloat(item.statistics.filter(item => item.key == 'total_expend').pop().value):0  }}
            </template>
            <!-- 操作template -->
            <!-- <template v-slot:uuid="{ item }">
              <a @click="GetDetail(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-LIST.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
                <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
              </a>
              <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
                <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
              </a>
            </template> -->
            <template v-slot:edit="{ item }">
              <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
                <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
              </MDBBtn>
            </template>
            <template v-slot:uuid="{ item }">
              <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
                <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
              </MDBBtn>
            </template>
          </DataTable>
        </MDBContainer>
    </MDBCardBody>
  </MDBCard>
  <DealerForm action="insert" v-model="add_dialog"></DealerForm>
  <DealerForm action="update" v-model="edit_dialog" v-model:uuid="EditUUid"></DealerForm>
</template>

<style scope>
  .form .select-option-icon {
    width: 100%;
    height: auto;
  }
  .form .rounded-circle {
    border-radius: unset!important;
  }
  .form .select-option {
    margin: 5px 0px;
  }
  .form .select-option-text {
    /*display: none;*/
  }
</style>

<script>
import { MDBCard, MDBCardBody, MDBIcon, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import DealerForm from '@/components/Form/Dealer/DealerForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive, inject } from "vue";
import { useI18n } from 'vue-i18n';
import JwtService from "@/core/services/jwt.service";

export default {
  name: "DealerList",
  components: {
    Breadcrumbs,
    MDBCard,
    MDBCardBody,
    DataTable,
    MDBIcon,
    DealerForm,
    PageLoading,
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const add_dialog = ref(false);
    const PageLoading = ref(true);
    const loading = ref(false);
    const EditUUid = ref(""); 
    const edit_dialog = ref(false);
    const ImageItems = reactive([]);
    const swipers = reactive([]);
    const Images = reactive({});
    const Products = reactive([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created_at'],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const where = reactive({
      email: "",
      name: "",
      sex: "",
      dealer: "",
      status: "",
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",width:"50px",class: "text-center"
      },
      { text: "推薦人編號", value: "refer_serial" },
      { text: i18n.t("COMMON.REFER"), value: "refer" },
      { text: "會員編號", value: "serial" },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.EMAIL"), value: "email" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false,width:"50px",class: "text-center"},
      // { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false,width:"50px",class: "text-center"}
    ]);
    const desserts = ref([]);
    const total = ref(0);
    const Common = CommonService;
    const APIURL = inject('APIURL');

    const GetImage = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Image",
          {}
        ).then(response => {
          if (response.status == 200) {
            response.data.data.map(record => {
              ImageItems.push({
                text: `${record.filename}.${record.extension}`,
                value: record.id,
                icon: `${APIURL}/storage/${record.directory}/${record.filename}.${record.extension}`
              });
            });
            resolve(response);
          }
        });
      });
    };

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Dealer",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetProduct = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Product",
          {
            params: {
              page: 1,
              limit: -1,
              sortBy: ["created_at"],
              sortDesc: [],
            }
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(item => {
              Products.push(item);
            });
            resolve(response);
          }
        });
      });
    };
    const Export = function() {
      var obj = {
        token: JwtService.getToken(),
      };
      window.open(`${process.env.VUE_APP_API_URL}/Admin/v1/DealerExport?${Object.entries(obj).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`);
    };
    return {
      add_dialog,
      PageLoading,
      loading,
      EditUUid,
      edit_dialog,
      ImageItems,
      swipers,
      options,
      headers,
      desserts,
      Common,
      total,
      GetImage,
      GetTable,
      GetProduct,
      Images,
      Products,
      where,
      Export
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      Images: this.Images,
      Products: this.Products
    }
  },
  mounted() {
    Promise.all([
      this.GetTable(),
      // this.GetImage(),
      // this.GetProduct(),
      this.$store.dispatch("country"),
      this.$store.dispatch("web"),
      this.$store.dispatch("currencyList"),
    ])
    .then(() => {
      this.PageLoading = false;
    })
  },
  watch: {
    options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  }
};
</script>
